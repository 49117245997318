import { render, staticRenderFns } from "./tourist_success.vue?vue&type=template&id=bc0d15ae&scoped=true&"
import script from "./tourist_success.vue?vue&type=script&lang=js&"
export * from "./tourist_success.vue?vue&type=script&lang=js&"
import style0 from "./tourist_success.vue?vue&type=style&index=0&id=bc0d15ae&prod&scoped=true&lang=scss&"
import style1 from "./tourist_success.vue?vue&type=style&index=1&id=bc0d15ae&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc0d15ae",
  null
  
)

export default component.exports