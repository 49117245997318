<template>
    <div class="page-regist info-box">
        <div class="title">
            <div class="success-box">
                <van-icon name="checked" />
                <div>
                    <b>登记成功</b><br>
                    <strong>欢迎进入<span>{{info.scenicTitle}}</span></strong><br>
                    <i><span>{{info.update_time}}</span></i>
                </div>
            </div>

        </div>
        <div class="page-form">
            <a href="https://xc.caict.ac.cn/#/login" class="epidemic">
                <img src="@/assets/images/epidemic.png" alt="">
            </a>
            <van-cell-group >
                <van-field label="姓名" v-model="info.name" placeholder="请输入您的姓名" maxlength="20" readonly/>
                <van-field label="证件类型" v-if="info.ID_type == 1" value="身份证" readonly/>
                <van-field label="证件类型" v-if="info.ID_type == 2" value="其他" readonly/>
                <van-field label="证件号" v-model="info.ID_num" placeholder="请填写证件号码" maxlength="18" readonly/>
                <van-field label="手机号码" v-model="info.phone" placeholder="请填写11位手机号" maxlength="11" readonly/>
                <van-field label="现住地址"  v-model="info.areaText" placeholder="请选择现住地址" readonly/>
                <van-field label=" " v-model="info.address" placeholder="请输入详细地址" readonly/>
                <!--<van-field label="交通方式" v-if="info.traffic == 0" value="其他" readonly/>-->
                <!--<van-field label="交通方式" v-if="info.traffic == 1" value="自驾" readonly/>-->
                <!--<van-field label="交通方式" v-if="info.traffic == 2" value="火车" readonly/>-->
                <!--<van-field label="交通方式" v-if="info.traffic == 3" value="飞机" readonly/>-->
                <!--<van-field label="交通方式" v-if="info.traffic == 4" value="大巴" readonly/>-->
                <!--<van-field label="交通方式" v-if="info.traffic == 5" value="网约车" readonly/>-->
                <van-field label="车牌信息" v-if="info.traffic == 1 || info.traffic == 4 || info.traffic == 5" v-model="info.carInfo" readonly/>
                <van-field label="车次信息" v-if="info.traffic == 2" v-model="info.carInfo"  readonly/>
                <van-field label="航班信息" v-if="info.traffic == 3" v-model="info.carInfo" readonly/>
                <van-field label="其他信息" v-if="info.traffic == 0" v-model="info.carInfo" readonly/>
                <van-field label="预约日期" v-model="info.appointmentDate" readonly/>
                <van-field label="预约场次" v-model="info.ticket" readonly/>

            </van-cell-group>
            <div>
                <img :src="imgCode" class="ewmImg" v-if="showImg">
                <div id="qrCode" ref="qrCodeDiv" v-if="showImg==false"></div>
            </div>

        </div>
    </div>
</template>

<script>
    import {PUBLIC} from '@/libs/const'
    import { NavBar, DateField, SexField, PickerField} from '_c';
    import {Field,Icon , Uploader, SwitchCell, Panel, Cell, CellGroup, Button,Checkbox } from 'vant';
    import config from '@/config';
    import QRCode from 'qrcodejs2';
    export default{
        name: 'Regist',
        components: {
            [Icon.name]: Icon,
            [NavBar.name]: NavBar,
            [DateField.name]: DateField,
            [SexField.name]: SexField,
            [PickerField.name]: PickerField,
            [Field.name]: Field,
            [Uploader.name]: Uploader,
            [SwitchCell.name]: SwitchCell,
            [Panel.name]: Panel,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Button.name]: Button,
            [Checkbox.name]: Checkbox,
            [config.name]: config,
        },
    data(){
        return {
            consts: PUBLIC,
            isSubmiting: false,
            info: {
                id: 0,
                name: '',
                areaText: '',
                province_code: '',
                city_code: '',
                county_code: '',
                traffic: 0,
                trafficText: 0,
                ID_type: 1,
                ID_num: '',
                phone: '',
                carInfo: '',
                isAgree: true,
                address:'',
                scenicTitle:'',
                update_time:'',
                appointmentDate:'',
                ticket:''
            },
            guardians: [],
            imgCode:'',
            showImg:null
        }
    },
    created(){
        this.id = this.$route.params.id;
        this.scenicId = this.$route.params.scenicId;
        let data = {}
        data["id"] = this.id;
        data["scenicId"] = this.scenicId;
        this.$post('/tourist/getUserAndScenic', data)
            .then(res => {
                if (res.flag==0){
                    let info = res.object;
                    this.info.name = info.name;
                    this.info.ID_num = info.ID_num;
                    this.info.ID_type = info.ID_type;
                    this.info.address = info.address;
                    this.info.update_time = info.update_time;
                    this.info.areaText = info.province + " " + info.city + " " + info.county;
                    this.info.id = info.id;
                    this.info.phone = info.phone;
                    this.info.traffic = info.traffic;
                    this.info.province_code = info.province_code;
                    this.info.scenicTitle = info.scenicTitle;
                    if (info.startTime == null || info.endTime == null
                        || info.startTime == "" || info.endTime == ""
                        || info.appointmentDate == "" || info.appointmentDate == null) {
                        this.info.ticket = "---";
                        this.info.appointmentDate = "---";
                    } else {
                        this.info.ticket = info.startTime + "-" + info.endTime;
                        this.info.appointmentDate = info.appointmentDate;
                    }
                    if (info.carInfo==null || info.carInfo==""){
                        this.info.carInfo = "---";
                    } else {
                    this.info.carInfo = info.carInfo;
                    }
                    if(info.qrcode_url && info.qrcode_url.indexOf('https')!='-1'){
                        this.showImg = true
                        this.imgCode = info.qrcode_url
                    }else{
                        if(info.check_code){
                            this.showImg = false
                            this.bindQRCode(info.check_code)
                        }
                    }
                }else {
                    this.$router.replace({path: '/tourist/failed'});
                }
            })
    },
    methods:{
         bindQRCode (data) {
            console.log(data);
            let _this = this
            _this.$nextTick(() => {
                new QRCode(_this.$refs.qrCodeDiv, {
                    text: data,
                    width: 200,
                    height: 200,
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                })
            })
        }
    }
}
</script>
<style scoped lang="scss">
    .page-regist{
        display: flex;
        flex-direction: column;
        background-color:#07c160;
        min-height: 100vh;
        overflow-y: auto;
        .page-form{
            position: relative;
           margin-top: 0;
            .epidemic{
                position: absolute;
                top: 1vw;
                right: 0;
                z-index: 9;
                img{
                    width: 30vw;
                }
            }
        }
        .title{
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
            i{
                color: #fff;
                span{
                    height: 4vw;
                    line-height: 4vw;
                }
            }

        }
    }
    .success-box {
        display: flex;
        justify-content: center;
        align-items: center;
        >div{
            max-width: 50vw;
        }
        .van-icon{
            margin-top: -4vw;
            font-size: 16vw;
            margin-right: 4vw;
        }
    }
</style>
<style lang="less" >

    .page-form{
        margin:4vw 4vw 0;
        border-radius: 8px;
        padding: 0 ;
        background-color: #fff;
        // overflow: hidden;
    }
.page-result{
    padding: 15vh 16px 16px;
    text-align: center;

    .page-icon.success{
        color: #07c160;
    }
    .page-tip{
        color: gray;
        display: block;
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
    .title {
        padding: 2vw 0;
        text-align: center;
        color:#07c160;
        font-size: 3.6vw;
        line-height: 2;
        b{
            font-weight: normal;
            font-size: 6vw;
        }
        strong{
            font-size: 4vw;
            font-weight:normal;
        }
        i{
            font-style: normal;
            color: #666;
        }
    }
    #qrCode{
        width: 200px;
        height: 200px;
        margin:30px auto;
    }
    .ewmImg{
        width: 200px;
        height: 200px;
        margin:30px auto;
        display: block;
    }
    
</style>

